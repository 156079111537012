import React from 'react';
import styled from 'styled-components';
import {message, Button} from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import usePosErrorHandler from '../../Hooks/usePosErrorHandler';
import useProductGroups, {CatType} from '../../Hooks/useProductGroups';
import {
  filterAllowedProductsBySelectedStore,
  filterExternalProducts,
} from '../../Components/ProductList/helper';
import usePosEdit from '../../Hooks/usePosEdit';

export default function EditPosStickerProductsPage(props) {
  const [store, setStore] = React.useState(null);
  const {name, station, token} = qs.parse(props.location.search);
  const [stationIdx, setStationIdx] = React.useState(-1);
  const [products, setProducts] = React.useState([]);
  const [values, setValues] = React.useState({});
  const {errorHandler, renderError} = usePosErrorHandler();
  const [meals] = useOutlet('mealCategories');
  const [loading, setLoading] = React.useState(false);

  const {setDone} = usePosEdit({values: values});

  // force re-render while categories is fetched
  useOutlet('categories');

  React.useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const store = await JStorage.fetchOneDocument('store', {name}, null, {
          token,
        });
        setStore(store);

        const _stationIdx = store.stations.findIndex((s) => s.name === station);
        setStationIdx(_stationIdx);
        setValues((store.stations[_stationIdx] || {}).sticker_products || {});

        const products = await AppActions.fetchProducts({onlyPublic: false});
        let _products = filterAllowedProductsBySelectedStore({
          products,
          selectedStore: store,
          meals,
        });

        _products = _products.concat(filterExternalProducts({products, meals}));

        setProducts(_products);
      } catch (ex) {
        console.warn('EditPostStationProductsPage ex', ex);
        errorHandler(ex);
        // alert('User not found!');
      }
      setLoading(false);
    }

    if (name && station && token && meals?.length > 1) {
      fetchData();
    }
  }, [name, station, token, meals]);

  const groups = useProductGroups({
    products,
    catType: CatType.LABEL,
  });

  return (
    <Wrapper>
      <h1>
        {store?.name} 貼紙機工作區: {station}
      </h1>
      <div style={{marginBottom: 6}}>{loading && '載入中...'}</div>
      {renderError()}
      {!store || stationIdx === -1 ? null : (
        <div className="content">
          <div className="group">
            <div className="header">
              <h3>全部商品</h3>
              <Button
                size="small"
                type="primary"
                disabled={loading}
                onClick={() => {
                  setValues(
                    products.reduce((sum, p) => {
                      sum[p.id] = true;
                      return sum;
                    }, {}),
                  );
                }}>
                全選
              </Button>

              <Button
                size="small"
                disabled={loading}
                style={{marginLeft: 5}}
                onClick={() => {
                  setValues({});
                }}>
                全不選
              </Button>
            </div>
          </div>
          {groups
            .filter((group) => group.items?.length > 0)
            .map((group) => {
              const {cat, items} = group;
              return (
                <div key={cat.name} className="group">
                  <div className="header">
                    <h3>{cat.name}</h3>
                    <Button
                      size="small"
                      type="primary"
                      disabled={loading}
                      onClick={() => {
                        const nextValues = {...values};
                        for (const p of items) {
                          nextValues[p.id] = true;
                        }
                        setValues(nextValues);
                      }}>
                      全選
                    </Button>
                    <Button
                      size="small"
                      disabled={loading}
                      style={{marginLeft: 5}}
                      onClick={() => {
                        const nextValues = {...values};
                        for (const p of items) {
                          delete nextValues[p.id];
                        }
                        setValues(nextValues);
                      }}>
                      全不選
                    </Button>
                  </div>

                  {items.map((product) => {
                    const id = `${cat.name}-${product.id}`;
                    return (
                      <div key={product.id}>
                        <input
                          disabled={loading}
                          type="checkbox"
                          id={id}
                          checked={!!values[product.id]}
                          onChange={(e) => {
                            const nextValues = {...values};
                            if (e.target.checked) {
                              nextValues[product.id] = true;
                            } else {
                              delete nextValues[product.id];
                            }
                            setValues(nextValues);
                          }}
                        />
                        <label htmlFor={id} style={{marginLeft: 8}}>
                          {product.name}{' '}
                        </label>
                        <div
                          style={{
                            color: '#ccc',
                            fontSize: 12,
                            marginLeft: 22,
                          }}>
                          {product.id}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

          <div className="actions">
            <Button
              type="primary"
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                try {
                  const nextStations = [...store.stations];
                  nextStations[stationIdx].sticker_products = values;
                  await JStorage.updateDocument(
                    'store',
                    {name},
                    {
                      ...store,
                      stations: nextStations,
                    },
                    {
                      token,
                    },
                  );
                  setDone(true);
                } catch (ex) {
                  console.warn(ex);
                  errorHandler(ex);
                  message.error('發生錯誤, 無法進行編輯！');
                }
                setLoading(false);
              }}>
              確認修改
            </Button>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #eee;
  padding: 12px;
  min-height: 100vh;

  & > h1 {
    font-size: 20px;
    margin-bottom: 6px;
    font-weight: 400;
  }

  & > .content {
    margin: 0 auto;
    border-radius: 6px;
    padding: 12px;
    background-color: white;

    & > .group {
      padding: 10px 0;
      & > .header {
        & > h3 {
          flex: 1;
          font-weight: 400;
        }

        display: flex;
        padding-bottom: 5px;
        border-bottom: 1px solid #ccc;
      }

      & > * {
        margin: 10px 0;
        display: flex;
        align-items: center;
      }
    }

    & > .actions {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
